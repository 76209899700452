<template>
  <b-modal
    :no-close-on-backdrop="loading > 0"
    :hide-header-close="loading > 0"
    ref="deleteSlots"
    @hide="modalHidden"
    :title="$t('calendarModule.deleteSlots.title')"
    :hide-footer="true"
  >
    <div v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else-if="finished">
      <b-icon-check /> {{ $t("calendarModule.deleteSlots.finished") }}
    </div>
    <div v-else>
      <p>
        {{
          $t("calendarModule.deleteSlots.amountInfo").replace(
            "{amount}",
            slots.length
          )
        }}
      </p>
      <b-button
        v-if="isClientRequest"
        size="xs"
        variant="danger"
      >
        {{ $t("calendarModule.deleteSlots.confirm") }}
      </b-button>
      <b-button
        v-if="!isClientRequest"
        size="xs"
        variant="danger"
        @click="() => confirmed()"
      >
        {{ $t("calendarModule.deleteSlots.confirm") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import { mapGetters } from "vuex";

export default {
  name: "DeleteSlots",
  props: ["slots", "isClientRequest"],
  data() {
    return {
      currentUser: null,
      cmApi,
      loading: 0,
      loadingError: false,
      finished: false
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"])
  },
  methods: {
    slotsChanged() {
      this.$emit("slotsChanged");
    },
    modalHidden() {
      this.$emit("hidden");
    },
    setSearchIdentifier(slotId, identifier) {
      this.searchIdentifiers[slotId] = identifier;
    },
    confirmed() {
      for (const slot of this.slots) {
        this.loading++;
        cmApi.slot
          .deleteTimeSlot(this.currentUser._id, slot._id)
          .then(res => {
            this.loading--;
            this.loadingError = false;
            if (!this.loading) {
              this.slotsChanged();
            }
          })
          .catch(e => {
            this.loading--;
            this.loadingError = false;
          });
      }
    }
  },
  mounted() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    this.$refs["deleteSlots"].show();
  }
};
</script>
